import React, { Component } from "react";
import { withRouter } from "react-router";
import ShowcaseMenu from './showcase'
import "./menu.scss"


class SideMenu extends Component {

  componentDidMount(){
    let isSideBarCollapsed = localStorage.getItem("big-showcase-sidebar-collapsed");
    if(isSideBarCollapsed === "false"){
      this.initMenu();
    } 
  }

  deactivateMenu = (e) => {
    document.getElementById("menu-close-icon").style.display ="none";
    document.getElementById("menu-burger-icon").style.display = "block";
    document.getElementById("side-nav").style.left = "-180px";
    document.getElementById("bb-container-main").style.left = "64px";
    document.getElementById("bb-container-main").style.width = "calc(100% - 64px)";
    document.getElementById("bb-showcase-menu").style.display = "none";   
    
    localStorage.setItem("big-showcase-sidebar-collapsed", true);
  }

  activateMenu = (e) => {     
    localStorage.setItem("big-showcase-sidebar-collapsed", false);
    this.initMenu();
  }
  initMenu = () => {
    document.getElementById("menu-close-icon").style.display = "block";
    document.getElementById("menu-burger-icon").style.display = "none";
    document.getElementById("side-nav").style.left = "0px";
    document.getElementById("bb-container-main").style.left = "250px";
    document.getElementById("bb-container-main").style.width = "calc(100% - 250px)";
    document.getElementById("bb-showcase-menu").style.display = "block";  
  }

  render() {
 
    return (
      <React.Fragment>      
        
        <div id="side-nav" className="side-nav d-flex flex-column justify-content-between">
          <div className="py-3">
            <div className="menu-icon-container mb-2 d-flex justify-content-end">
              <div id="menu-burger-icon" className="cursor-pointer fa fa-bars fa-2x text-white align-self-center" onClick={() => this.activateMenu()}></div>
              <div id="menu-close-icon" className="cursor-pointer fa fa-times fa-2x text-white align-self-center" onClick={() => this.deactivateMenu()}></div>
            </div>           

              <div id="bb-menu-container" className="d-flex flex-row bb-menu-container">
                <ShowcaseMenu />
              </div> 
            
          </div>
        </div>
      </React.Fragment>
    );
  }
}


export default withRouter(SideMenu);
