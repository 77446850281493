import React, { Component } from 'react'
import { BrowserRouter, Router } from 'react-router-dom';
import Routes from './routes';
import { connect } from 'react-redux';

import History from './helpers/history';
import { getInfo } from "./redux/theme/theme.actions"
import Spinner from './components/utilities/Spinner'
import { initTheme } from './theme'

class ThemeContainer extends Component {

    componentDidMount() {
        this.props.getInfo();
    }

    componentDidUpdate(prevProps) {
        const { info, loading } = this.props;

        if (loading !== prevProps.loading) {
            if (!loading && info) {
                initTheme(info);
            }
        }
    }

    render() {
        const { loading } = this.props;

        return (
            <BrowserRouter>
                <Router history={History}>
                    {loading === false ?
                        <Routes />
                        :
                        <div className="text-center d-flex flex-column justify-content-center h-100">
                            <Spinner width="100" height="100" />
                            <p>Loading...</p>
                        </div>
                    }
                </Router>
            </BrowserRouter>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.theme.loading,
        info: state.theme.info
    }
};

export default connect(mapStateToProps, { getInfo })(ThemeContainer);

