import React, { Component } from "react";
import SideMenu from "./menu/menu";

import ClearCache from "./ClearCache";

class App extends Component { 

  render() {
    return (
      <React.Fragment>
        <ClearCache auto={true} storageKey="SHOWCASE-APP-VERSION">
          
        {// eslint-disable-next-line
        ({}) => (
         //(!loading && !isLatestVersion) && notify("Reloading Buddy to apply the latest updates.", "s")
          <></>
        )}
        </ClearCache>
          <div className="bb-container">
            <SideMenu />
            <div id="bb-container-main" className="bb-container-main">
              {/* <div id="menu-burger-icon" className="menu-burger-icon cursor-pointer pl-2 pt-2 mt-1">
                <div className="fa fa-bars fa-2x text-white" onClick={this.activatemenu}></div>
              </div> */} 
              {this.props.children}

            </div>
          </div>
        
      </React.Fragment>
    );
  }
}

export default App;
