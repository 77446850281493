import React, { Component } from 'react'
import Loader from 'react-loader-spinner'

export default class Spinner extends Component {
   
    render() {
        const { className, type, color, height, width } = this.props;

        return (
            <Loader
                className={className}
                type={type ? type : "Oval"}
                color={color ? color : "#EC1555"}
                height={height ? height : "100"}
                width={width ? width : "100"}
            />
        );
    }
}