import axios from 'axios';

import { ThemeConstants } from './theme.constants'
import config from '../../helpers/config'

const ROOT_URL = config.apiServer;

/* For whitelabel */

const loading = (status) => {
    return {
        type: ThemeConstants.GET_THEME_INFO_LOADING,
        payload: status
    };
};

export const getInfo = () => {
    return (dispatch) => {
        dispatch(loading(true));
        
        axios.post(`${ROOT_URL}/info`, {})
            .then(response => {
                if (response.data && response.data.success) {
                    dispatch({ type: ThemeConstants.GET_THEME_INFO, payload: response.data.data });
                }
                else {
                    dispatch({ type: ThemeConstants.GET_THEME_INFO_ERROR, payload: "" });
                }

                dispatch(loading(false));
            }).catch(() => {
                dispatch({ type: ThemeConstants.GET_THEME_INFO_ERROR, payload: "" });
                dispatch(loading(false));
            });
    };
};