import { combineReducers } from 'redux';

import { showcase } from './showcase/showcase.reducer'
import { theme } from './theme/theme.reducer'

const rootReducer = combineReducers({
    showcase,
    theme
});

//export default rootReducer;

export default (state, action) => (
    rootReducer(state, action)
)
