import React from 'react';
import ReactDOM from 'react-dom';

import History from './helpers/history';
import { store } from './redux/store'
import { Provider } from 'react-redux';

import * as serviceWorker from './serviceWorker';

import ReactGA from 'react-ga';
import ThemeContainer from './ThemeContainer'

import './index.scss'

if (process.env.NODE_ENV !== "development") {
    ReactGA.initialize('UA-127070262-1');

    History.listen((location, action) => {
        ReactGA.pageview(location.pathname + location.search);
    });

}

ReactDOM.render(
    <Provider store={store}>
        <ThemeContainer />
    </Provider>, document.getElementById('root'));

serviceWorker.unregister();
