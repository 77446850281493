import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getAllDimensions, getAllObjectives, getAllVerticals, getAllFormats, getAllCountries,
        setActiveFormats,setActiveDevices,setActiveVerticals,setActiveObjectives,setActiveDimensions,
        setSearchName,setSortBy,setSortType,setCountry, resetFilter
} from '../../../redux/showcase/helpers/helpers.actions';

import { withRouter } from "react-router";
import { getQueryStringParams, removeClass } from "../../utilities/customFunctions"
import classnames from 'classnames';
import History from "../../../helpers/history";
import { Devices } from "../../../helpers/devices";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import CheckboxGroup from 'react-checkbox-group';

import "../menu.scss"


class ShowcaseMenu extends Component {    

    componentDidMount() {

        const { getAllDimensions, getAllObjectives, getAllVerticals, getAllFormats, getAllCountries } = this.props;

            getAllFormats()
            getAllDimensions()
            getAllObjectives()
            getAllVerticals();
            getAllCountries();

            this._initFilters();
    } 

    componentDidUpdate(prevProps){
        const { location } = this.props;
        if(location !== prevProps.location){
            this._initFilters();
        }
    }

    _initFilters(){
        const { 
            setActiveFormats,setActiveDevices,setActiveVerticals,setActiveObjectives,setActiveDimensions,
            setSearchName,setSortBy,setSortType,setCountry } = this.props;

        let searchParams = getQueryStringParams(this.props.location.search);
        let activeDevices = [],
            name = "",
            activeFormats = [],
            activeDimensions = [],
            activeVerticals = [],
            activeObjectives = [],
            sortBy = "",
            sortType = "",
            activeCountry = "";

        if (searchParams) {
            activeDevices = searchParams.device && searchParams.device.split(',');
            name = searchParams.name;
            activeFormats = searchParams.format && searchParams.format.split(',');
            activeDimensions = searchParams.dimension && searchParams.dimension.split(',');
            activeVerticals = searchParams.vertical && searchParams.vertical.split(',');
            activeObjectives = searchParams.objective && searchParams.objective.split(',');
            sortBy = searchParams.sortby;
            sortType = searchParams.sorttype;
            activeCountry = searchParams.country && searchParams.country.split(',');
        }

        setActiveDevices(activeDevices || [])
        setSearchName(name || "")
        setActiveFormats(activeFormats || [])
        setActiveVerticals(activeVerticals || [])
        setActiveDimensions(activeDimensions || [])
        setActiveObjectives(activeObjectives || [])
        setSortBy(sortBy || "")
        setSortType(sortType || "")
        setCountry(activeCountry || [])
    }

    _initialize(Checkbox, name, value, id) {
        const menuItem = this.props[name];
        if (menuItem && menuItem.data && menuItem.data.length) {
            const { data } = menuItem;
            if (data instanceof Array) {
                return data.map(function (o, i) {
                    let isActive = value && (value.includes(o[id].toString()) || value.includes(o[id]));
                    let isProSkin = (name === "format") && o.name && (o.name.includes("Pro-Skin") || o.name.includes("Pro-Scroll"))
                    let hideDimensions = (name === "dimension") && o.name && (o.name.includes("Interstitial") || o.name.includes("1920x1080"))
                    
                    return (
                        isProSkin || hideDimensions ? null : 
                        <label className={classnames('list-group-item showcase-sub-menu ripple', isActive ? 'active' : '')} key={i}>
                            <Checkbox value={o[id]} />
                            {name === "dimension" ? o.name.replace("x", " x ") : o.name}
                            {/* <i className={classnames('fa fa-times float-right', isActive ? '' : 'd-none')} aria-hidden="true"></i> */}
                        </label>);
                })
            }
        }
        else {
            return null;

        }
    }

    _initializeDevice(Checkbox) {
        var device = this.props.activeDevices;
        if (Devices) {
            if (Devices instanceof Array) {
                return Devices.map(function (o, i) {
                    let isActive = device && device.includes(o.value.toString());
                    return (
                        <label className={classnames('list-group-item showcase-sub-menu ripple', isActive ? 'active' : '')} key={i}>
                            <Checkbox value={o.value} />
                            {o.label}
                            {/* <i className={classnames('fa fa-times float-right', isActive ? '' : 'd-none')} aria-hidden="true"></i> */}
                        </label>);
                })
            }
        }
        else {
            return null;
        }
    }

    applyFilters = () => {
        const { activeDevices, activeFormats, activeVerticals, activeDimensions,
            activeObjectives, sortBy, sortType, activeCountry, name } = this.props;

        removeClass("showcase-manage-sub-menu", "active");
        History.push(`/filter?device=${activeDevices}&format=${activeFormats}&vertical=${activeVerticals}&dimension=${activeDimensions}&objective=${activeObjectives}&sortby=${sortBy}&sorttype=${sortType}&country=${activeCountry}&name=${name}&offset=1`);
    }

    handleFormatChange = async (value) => {
        await this.props.setActiveFormats(value);
        this.applyFilters();
    }
    handleDimensionChange = async (value) => {
        await this.props.setActiveDimensions(value);
        this.applyFilters();
    }
    handleVerticalChange = async (value) => {
        await this.props.setActiveVerticals(value);
        this.applyFilters();
    }
    handleObjectiveChange = async (value) => {
        await this.props.setActiveObjectives(value);
        this.applyFilters();
    }
    handleDeviceChange = async (value) => {
        await this.props.setActiveDevices(value);
        this.applyFilters();
    }
    handleCountryChange = async (value) => {
        await this.props.setCountry(value.toString());
        this.applyFilters();
    }

    items = () => {
        return [
            { name: "format", value: this.props.activeFormats, onChange: this.handleFormatChange, id: "id" },
            { name: "dimension", value: this.props.activeDimensions, onChange: this.handleDimensionChange, id: "name" },
            { name: "vertical", value: this.props.activeVerticals, onChange: this.handleVerticalChange, id: "id" },
            { name: "objective", value: this.props.activeObjectives, onChange: this.handleObjectiveChange, id: "id" },
            //{ name: "country", value: this.props.activeCountry, onChange: this.handleCountryChange, id: "id" },
        ];
    };

     render() {
    
        return (
            <>
           
            <Accordion allowMultipleExpanded={true} allowZeroExpanded={true} className="bb-showcase-menu" id="bb-showcase-menu">
                <AccordionItem key={111} className="bb-showcase-menu__item bb-menu-item">
                    <AccordionItemHeading className="bb-showcase-menu__heading">
                        <AccordionItemButton className="bb-showcase-menu__button bb-menu-item">
                            <span className="text-capitalize">Environment</span>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="bb-showcase-menu__panel">
                        <CheckboxGroup className="d-flex flex-column list-group mt-2" name="format"
                            value={this.props.activeDevices} onChange={this.handleDeviceChange}
                        >
                            {(Checkbox) => (this._initializeDevice(Checkbox))}
                        </CheckboxGroup >
                    </AccordionItemPanel>
                </AccordionItem>

                {this.items().map((f, i) => {
                    return (
                        
                        <AccordionItem key={i} className="bb-showcase-menu__item bb-menu-item">
                            <AccordionItemHeading className="bb-showcase-menu__heading">
                                <AccordionItemButton className="bb-showcase-menu__button bb-menu-item">                                    
                                    <span className="text-capitalize">{f.name }</span>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="bb-showcase-menu__panel">
                                <CheckboxGroup className="d-flex flex-column list-group mt-2" name="format"
                                    value={f.value} onChange={f.onChange}
                                >
                                    {(Checkbox) => (this._initialize(Checkbox, f.name, f.value, f.id))}
                                </CheckboxGroup >
                            </AccordionItemPanel>
                        </AccordionItem>
                
                    )
                })
                }                            
            </Accordion>
                 
            </>
        )
    }


}


const mapStateToProps = (state) => {
    return {      
        format: state.showcase.helpers.formats,
        dimension: state.showcase.helpers.dimensions,
        vertical: state.showcase.helpers.verticals,
        objective: state.showcase.helpers.objectives,
        country: state.showcase.helpers.countries,

        activeDevices: state.showcase.helpers.activeDevices,
        name: state.showcase.helpers.name,
        activeFormats: state.showcase.helpers.activeFormats,
        activeDimensions: state.showcase.helpers.activeDimensions,
        activeVerticals: state.showcase.helpers.activeVerticals,
        activeObjectives: state.showcase.helpers.activeObjectives,
        sortBy: state.showcase.helpers.sortBy,
        sortType: state.showcase.helpers.sortType,
        activeCountry: state.showcase.helpers.activeCountry,
    }
};

const mapDispatchToProps = {
    getAllDimensions,
    getAllObjectives,
    getAllVerticals,
    getAllFormats,
    getAllCountries,

    setActiveFormats,setActiveDevices,setActiveVerticals,setActiveObjectives,setActiveDimensions,
    setSearchName,setSortBy,setSortType,setCountry, resetFilter
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShowcaseMenu));