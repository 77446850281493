class Config {
    constructor() {        

        this.apiServer = "https://engine.xaxid-showcase.com";
        this.buddyEngineServer = "https://engine.bigbuddy.io";
        this.previewServer = "https://preview.studio/";
        this.dataLimit = 12;
        this.dataLimitForDashboard = 12;       
        this.ROOT_URL = this.apiServer;
    }
}
export default (new Config()); 