import React, { Component } from 'react'
import './404.scss'

export default class ErrorPage extends Component {

    render() {
        return (
            <div className="bb-error-page d-flex flex-column justify-content-center align-items-center flex-grow-1">                
                <img className="img-fluid floating" src="/images/lostinspace.png" alt="" />
                <h2 className="mb-3">This page is lost in space.</h2>
                <div className="stars"></div>
                <div className="twinkling"></div>
            </div>

        );
    }
} 