import { HelpersConstants } from './helpers.constants';

export const helpers = (state = {}, action) => {

    switch (action.type) {
        case HelpersConstants.BB_SHOWCASE_GET_ALL_VERTICALS_INIT:
            return { ...state, verticals: { loading: action.payload } };
        case HelpersConstants.BB_SHOWCASE_GET_ALL_VERTICALS_SUCCESS:
        case HelpersConstants.BB_SHOWCASE_GET_ALL_VERTICALS_ERROR:
            return { ...state, verticals: { data: [...action.payload], loading: false } };;
        case HelpersConstants.BB_SHOWCASE_EMPTY_VERTICALS:
            return { ...state, verticals: "" };


        case HelpersConstants.BB_SHOWCASE_GET_ALL_FORMATS_INIT:
            return { ...state, formats: { loading: action.payload } };
        case HelpersConstants.BB_SHOWCASE_GET_ALL_FORMATS_SUCCESS:
        case HelpersConstants.BB_SHOWCASE_GET_ALL_FORMATS_ERROR:
            return { ...state, formats: { data: [...action.payload], loading: false } };;
        case HelpersConstants.BB_SHOWCASE_EMPTY_FORMATS:
            return { ...state, formats: "" };


        case HelpersConstants.BB_SHOWCASE_GET_ALL_DIMENSIONS_INIT:
            return { ...state, dimensions: { loading: action.payload } };
        case HelpersConstants.BB_SHOWCASE_GET_ALL_DIMENSIONS_SUCCESS:
        case HelpersConstants.BB_SHOWCASE_GET_ALL_DIMENSIONS_ERROR:
            return { ...state, dimensions: { data: [...action.payload], loading: false } };;
        case HelpersConstants.BB_SHOWCASE_EMPTY_DIMENSIONS:
            return { ...state, dimensions: "" };


        case HelpersConstants.BB_SHOWCASE_GET_ALL_OBJECTIVES_INIT:
            return { ...state, objectives: { loading: action.payload } };
        case HelpersConstants.BB_SHOWCASE_GET_ALL_OBJECTIVES_SUCCESS:
        case HelpersConstants.BB_SHOWCASE_GET_ALL_OBJECTIVES_ERROR:
            return { ...state, objectives: { data: [...action.payload], loading: false } };;
        case HelpersConstants.BB_SHOWCASE_EMPTY_OBJECTIVES:
            return { ...state, objectives: "" };


        case HelpersConstants.BB_SHOWCASE_GET_ALL_COUNTRIES_INIT:
            return { ...state, countries: { loading: action.payload } }; 
        case HelpersConstants.BB_SHOWCASE_GET_ALL_COUNTRIES_SUCCESS:
        case HelpersConstants.BB_SHOWCASE_GET_ALL_COUNTRIES_ERROR:
            return { ...state, countries: { data: [...action.payload], loading: false } };;
        case HelpersConstants.BB_SHOWCASE_EMPTY_COUNTRIES:
            return { ...state, countries: "" };

        case HelpersConstants.BB_SHOWCASE_GET_ALL_CREATIVES_INIT:
            return { ...state, creatives: { loading: action.payload } };
        case HelpersConstants.BB_SHOWCASE_GET_ALL_CREATIVES_SUCCESS:
        case HelpersConstants.BB_SHOWCASE_GET_ALL_CREATIVES_ERROR:
            return { ...state, creatives: { data: [...action.payload], loading: false } };;
        case HelpersConstants.BB_SHOWCASE_EMPTY_CREATIVES:
            return { ...state, creatives: "" };
        case HelpersConstants.BB_SHOWCASE_GET_ALL_CREATIVES_TOTAL_COUNT:
            return { ...state, total_creatives: action.payload }

        /* For dynamic search params */
        case HelpersConstants.BB_SHOWCASE_ACTIVE_DEVICES:
            return { ...state, activeDevices: action.payload };
        case HelpersConstants.BB_SHOWCASE_SEARCH_NAME:
            return { ...state, name: action.payload };
        case HelpersConstants.BB_SHOWCASE_ACTIVE_FORMATS:
            return { ...state, activeFormats: action.payload };
        case HelpersConstants.BB_SHOWCASE_ACTIVE_DIMENSIONS:
            return { ...state, activeDimensions: action.payload };
        case HelpersConstants.BB_SHOWCASE_ACTIVE_VERTICALS:
            return { ...state, activeVerticals: action.payload };
        case HelpersConstants.BB_SHOWCASE_ACTIVE_OBJECTIVES:
            return { ...state, activeObjectives: action.payload };
        case HelpersConstants.BB_SHOWCASE_SORTBY:
            return { ...state, sortBy: action.payload };
        case HelpersConstants.BB_SHOWCASE_SORTTYPE:
            return { ...state, sortType: action.payload };
        case HelpersConstants.BB_SHOWCASE_COUNTRY:
            return { ...state, activeCountry: action.payload };
        case HelpersConstants.BB_SHOWCASE_RESET_FILTER:
            return { ...state,  activeDevices: [], name: "", activeFormats: [], activeVerticals: [], activeDimensions: [], activeObjectives: [], sortBy: "", sortType: "", activeCountry: [] };

        default:
            return state;
    }
}