import { ThemeConstants } from './theme.constants';

export const theme = (state = {}, action) => {

    switch (action.type) {
       
        case ThemeConstants.GET_THEME_INFO:
            return { ...state, info: action.payload }
        case ThemeConstants.GET_THEME_INFO_ERROR:
            return { ...state, info: action.payload }
        case ThemeConstants.GET_THEME_INFO_LOADING:
            return { ...state, loading: action.payload }

        default:
            return state;
    }
};
