import React, { lazy, Suspense } from "react";
import { Route, Redirect, Switch, withRouter } from "react-router-dom";
import App from "../components/app";

import { Loader } from '../components/react-overlay-loader';
import withGA from "../helpers/GA/withGA-HOC";

import Error404 from '../components/404/'

import ErrorBoundary from '../components/error-boundary'
/* Showcase */
const Showcase = lazy(() => import('../components/showcase'))



const Routes = () => {
    return (
        <Switch>
             <App>

                <ErrorBoundary>
                    <Suspense fallback={<Loader containerStyle={{ backgroundColor: "transparent" }} loading={true} fullPage text="Loading..." />}>
                        <Switch>                           
                            {/* Showcase */}
                            <Route exact path="/" component={Showcase} />
                            <Route exact path="/filter" component={Showcase} />
                            <Route exact path="/404Error" component={Error404} />
                            <Redirect path="*" to="/404Error" />
                        </Switch>
                    </Suspense>
                </ErrorBoundary>


            </App>
        </Switch>
    );
};

export default withRouter(withGA(Routes));
