import React from 'react';
import ReactGA from 'react-ga';

const withGa = WrappedComponent => {
    const HOC = class extends React.Component {

        onPageView() {
            ReactGA.pageview(this.props.location.pathname + this.props.location.search)
        }

        componentDidMount() {
            this.onPageView();
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                />
            );
        }
    };

    return HOC;
};

export default withGa;