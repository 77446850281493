
import * as $ from 'jquery'

export const getQueryStringParams = query => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                let [key, value] = param.split('=');
                params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                return params;
            }, {}
            )
        : {}
};

export const getLabelFromValue = (opts, val) => {
    // eslint-disable-next-line
    var filter = opts.filter(o => val == (o.value));
    if (filter.length > 0) {
        if (filter[0].label) {
            return filter[0].label;
        }
        else {
            return "";
        }
    }
    return "";
};

export const getValueFromLabel = (opts, val) => {
    // eslint-disable-next-line
    var filter = opts.filter(o => val == (o.label));
    if (filter.length > 0) {
        return filter[0];
    }
    else {
        return [];
    }
};

export const getValueFromObject = (opts, val, objectname) => {
    // eslint-disable-next-line
    var filter = opts.filter(o => val == o[objectname]);
    if (filter.length > 0) {
        return filter[0];
    }
    else {
        return [];
    }
};

export const getAllValuesFromObject = (opts, val, objectname) => {

    if(val && opts){
        let valArr = val.split(",");
        let filter = [];
        for (let i = 0; i < valArr.length; i++) {
            filter.push(getValueFromObject(opts, valArr[i], objectname));
        }
        // eslint-disable-next-line
        //var filter = opts.filter(o => val == o[objectname]);
        if (filter.length > 0) {
            return filter;
        }
        else {
            return [];
        }
    }
    else {
        return [];
    }
    
};

export const addClass = (el, className) => {
    if (el.classList)
        el.classList.add(className);
    else
        el.className += ' ' + className;
}

export const removeClass = (el, className) => {
    $("." + el).removeClass(className);

    /* if (el.classList)
        el.classList.remove(className);
    else
        el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' '); */
}

export const toFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, typeof object[key] === "string" ? object[key].trim() : object[key]));
    return formData;
}

export const getFilename = (path) => {
    return path.substring(path.lastIndexOf('/') + 1);
}

export const getFileSize = (path) => {
    var img = new Image();
    img.onload = function () {
        return this.width + 'x' + this.height;
    }
    img.src = path;
}

export const loadDefaultThumbnail = (e) => {
    e.target.src = "/images/showcase-default-card.jpg";
}

export const getCommaSeparated = (object, valueOf) => {
    return object.map((o) => {
        return o[valueOf];
    }).join(",");
}

export const getSrcExtension = (src) => {
    return src.slice((src.lastIndexOf(".") - 1 >>> 0) + 2);
}